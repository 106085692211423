@import "antd.less";
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";

/* Helpers */

.rxd-auth-absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
}

.rxd-auth-hidden {
  display: none;
}

.rxd-auth-full-height {
  height: 100vh;
}

.rxd-auth-full-width {
  width: 100%;
}
