.cpm-auth-sensitivity-bar {
  width: 100%;
  height: 4px;

  &__step {
    position: relative;

    &__icon,
    &__input {
      position: absolute;
      top: -15px;

      &--left {
        left: 0;
      }

      &--right {
        right: 0;
      }
    }

    &__input {
      top: -45px;
      width: 120px;

      &--small {
        width: 90px;
      }

      &--right {
        right: -40px;
      }

      &--left {
        left: -40px;
      }
    }

    &--minimum {
      &-1 {
        background-color: var(--color-sensitivity-red-1);
      }

      &-2 {
        background-color: var(--color-sensitivity-red-2);
      }

      &-3 {
        background-color: var(--color-sensitivity-red-3);
      }
    }

    &--middle {
      &-1 {
        background-color: var(--color-sensitivity-yellow-1);
      }

      &-2 {
        background-color: var(--color-sensitivity-yellow-2);
      }

      &-3 {
        background-color: var(--color-sensitivity-yellow-3);
      }
    }

    &--maximum {
      &-1 {
        background-color: var(--color-sensitivity-green-1);
      }

      &-2 {
        background-color: var(--color-sensitivity-green-2);
      }

      &-3 {
        background-color: var(--color-sensitivity-green-3);
      }
    }
  }
}
