.rxd-draggable-table {
  .rxd-draggable-table-scrollable-zone {
    overflow-y: auto;
  }

  .rxd-disabled-row {
    color: #dcdcdc;
    pointer-events: none;
  }
}
