:root {
  /* Paddings  */
  --spacing-size-8: 8px;
  --spacing-size-16: 16px;
  --spacing-size-24: 24px;

  /* Font-size  */
  --font-size-12: 12px;

  /* Colors */
  --color-white: #fff;
  --color-secondary: rgba(0 0 0 45%);
  --color-brand: #002e5d;

  /* Left side menu */
  --var-collapsed-max-width-menu: 80px;
  --rxd-sidebar-header-height: 54px;

  /* colors */
  --color-sensitivity-red-1: #d45062;
  --color-sensitivity-red-2: #e07983;
  --color-sensitivity-red-3: #eda6ab;
  --color-sensitivity-yellow-1: #fadb50;
  --color-sensitivity-yellow-2: #ffeb7a;
  --color-sensitivity-yellow-3: #fff4a3;
  --color-sensitivity-green-1: #b7c2b6;
  --color-sensitivity-green-2: #a6b5a5;
  --color-sensitivity-green-3: #7fa87e;
}
