@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";

@font-family: "Roboto", "Roboto Light", sans-serif;

.ant-space {
  &.rxd-full-width {
    width: 100%;
  }
}

.ant-form-item {
  &.small-gap {
    margin-bottom: var(--spacing-size-8);
  }
}

.ant-input-affix-wrapper-disabled {
  background-color: #fff;
}
