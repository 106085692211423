.rxd-auth-sidebar-sider {
  z-index: 1010;

  &__menu {
    display: flex;
    flex-direction: column;
    height: 100vh;

    &__text--capitalize {
      text-transform: capitalize;
    }

    &__icon {
      svg {
        min-width: var(--spacing-size-16);
      }
    }
  }
}
